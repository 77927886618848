import React from 'react';
import LogSwitch from '../../components/admin/LogSwitch';
import Layout from '../../templates/layout';
import HasUserRole from '../../templates/hasUserRole';

const logSwitch = () => {
  return (
    <Layout >
      <HasUserRole role='admin' isPage>
        <LogSwitch />
      </HasUserRole>
    </Layout>
  );
};

export const Head = () => <title>Zmiana użytkownika | Zapłatomat</title>;

export default logSwitch;

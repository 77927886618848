import React, { Component } from 'react';
import {
  Container,
  Divider,
  Button,
  Alert,
  Box,
  TextField,
} from '@mui/material';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../actions';

function mapStateToProps(state) {
  return {
    logSwitch: state.logSwitch,
  };
}

class LogSwitch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      provider: '',
      error: '',
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.logSwitch !== this.props.logSwitch) {
      let logSwitch = this.props.logSwitch;
      if (logSwitch.access_token) {
        this.setState({ message: '' });
        let token = 'Bearer ' + logSwitch.access_token;
        localStorage.setItem('authToken', token);
        localStorage.setItem('refreshToken', logSwitch.refresh_token);
        this.props.actions.getUserSilent();
      } else {
        if (logSwitch.reason) {
          this.setState({ message: logSwitch.reason });
        }
      }
      this.setState({ loading: false });
    }
  }

  downloadFile() {
    const { content, filename } = this.props.exportCsv;
    const link = document.createElement('a');
    link.href = URL.createObjectURL(content);
    link.setAttribute('download', filename);
    link.click();
  }

  handleChange(e) {
    this.setState({
      email: e.target.value,
      error: '',
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    let data = {
      email: this.state.email,
    };
    this.setState({ loading: true });
    this.props.actions.logSwitch(data);
  }

  render() {
    return (
      <Container sx={{ marginTop: '50px' }}>
        <Divider>LogSwitch</Divider>
        <Box
          component='form'
          sx={{
            '& .MuiTextField-root': { width: '100%' },
            marginTop: '8px',
            marginBottom: '16px',
          }}
          noValidate
          autoComplete='off'
          onSubmit={e => this.handleSubmit(e)}
        >
          <TextField
            sx={{ marginBottom: '12px' }}
            id='email'
            label='Podaj email'
            name='email'
            value={this.state.email}
            onChange={this.handleChange.bind(this)}
          ></TextField>
          <br />
          <Button
            variant='contained'
            disableElevation
            type='submit'
            disabled={this.state.loading}
          >
            Przeloguj
          </Button>
        </Box>
        {this.state.message && (
          <Alert severity='error'>{this.state.message}</Alert>
        )}
      </Container>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(actions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(LogSwitch);
